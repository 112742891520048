import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import styled from "styled-components"
import PhoneImg from "../images/download-phone-white.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import LogoTransfez from "../images/transfez-new-logo-big.svg"
import "../components/layout.css"
import Seo from "../components/SEOComponents/Seo"

function ResetPassword() {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  useEffect(() => {
    setTimeout(() => {
      navigate("https://transfez.onelink.me/e9Ud/z4vk8348?af_force_deeplink=true")
    }, 500)
  }, [])

  return (
    <>
      <Seo title="reset-password" />
      <div
        className="container d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh", maxWidth: "1024px" }}
      >
        <div className="d-flex justify-content-center">
          {/* <div className="col-12 col-md-4 text-center mb-5 mb-md-0">
            <PhoneImage src={PhoneImg} alt="phone image" />
          </div> */}
          <div className="col-12 col-md-8 text-primary text-center text-md-right">
            <div className="heading d-flex flex-row w-100 mb-5 justify-content-center">
              <TitleH2>
                {" "}
                {isEnglish ? "You have successfully " : "Anda telah berhasil "}
                <br />
                {isEnglish
                  ? "reset your password"
                  : "menyetel ulang sandi Anda"}
              </TitleH2>
              <div className="check ml-4 mb-n4">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="5x"
                  style={{ color: "#DB64A0" }}
                />
              </div>
            </div>
            <p>
              <TitleP>
                {isEnglish
                  ? "Please continue to log in with your new password in Transfez apps."
                  : "Silakan lanjutkan login dengan kata sandi baru Anda di aplikasi Transfez."}
              </TitleP>
            </p>
            <Link to={isEnglish ? `/en/` : `/`}>
              <LogoImg src={LogoTransfez} alt="logo-transfez" />
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword

const PhoneImage = styled.img`
  max-height: 400px;
  max-width: 100%;
  height: auto;
  @media (max-width: 414px) {
    max-height: 200px;
  }
`

const TitleH2 = styled.h2`
  color: #102c57;
  font-family: "Typefez-bold";
  font-size: 2rem;
  text-align: right;
  flex: 1 1 auto;
  @media (max-width: 414px) {
    font-size: 18px;
  }
  > br {
    display: none;
    @media (min-width: 768px) {
      display: inline;
    }
  }
  @media (min-width: 576px) {
    flex-shrink: 1;
  }
`

const TitleP = styled.p`
  color: #102c57;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
`
const LogoImg = styled.img`
  width: 180px;
  height: 53px;
  margin-bottom: 1.5rem;
`
